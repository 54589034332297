<script>
	import Header from '$lib/layouts/header/Header.svelte';
	import Footer from '$lib/layouts/footer/Footer.svelte';
	import DisclaimerModal from '$lib/main/disclaimer/DisclaimerModal.svelte';
	import { disclaimerModalVisible } from '$lib/stores/storesShared';
	import query from 'query-store';
	import { page } from '$app/stores';
	import '../app.css';

	import { isMobile, mobileSize, queryString } from '$lib/stores/storesShared';

	let innerWidth;

	$: {
		$isMobile = innerWidth <= $mobileSize;
	}

	function updateQueryString() {
		if (typeof window !== 'undefined' && $page.path === '/') {
			$queryString = window.location.search;
		}
	}

	$: {
		$query;
		updateQueryString();
	}
</script>

<svelte:window bind:innerWidth />
{#if innerWidth}
	<div class="page h-screen {$disclaimerModalVisible ? 'overflow-hidden' : 'overflow-auto'}">
		{#if $disclaimerModalVisible}
			<DisclaimerModal />
		{/if}
		<div>
			<Header />
		</div>
		<main class=" h-full flex lg:relative flex-col lg:flex-row bg-mapBg">
			<slot />
		</main>
		<Footer />
	</div>
{/if}

<style lang="scss">
	@import '../lib/styles/global.scss';

	main {
		flex: 1;
		height: calc(100% - 3.5rem);
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}
</style>
