<script>
	import { queryString } from '$lib/stores/storesShared';
</script>

<footer class="p-10 bg-white border-t text-sm">
	<div class="flex text-center lg:text-left lg:justify-between flex-col lg:flex-row">
		<div class="mb-8">
			<div class="mb-2 font-bold">Kontakt</div>
			<div>ILS – Institut für Landes- und Stadtentwicklungsforschung gGmbH</div>
			<div>E-Mail: <a href="mailto:info@incora-flaeche.de">info@incora-flaeche.de</a></div>
		</div>
		<div class="mb-8">
			<div class="mb-2 font-bold">Unterstützt von</div>
			<div class="flex justify-around items-center flex-col lg:flex-row">
				<img
					src="/images/logo_bmvi.png"
					srcset="/images/logo_bmvi.png 1x, /images/logo_bmvi@2x.png 2x"
					alt="Logo BMVI"
				/>
				<img
					src="/images/logo_mfund.jpeg"
					srcset="/images/logo_mfund.png 1x, /images/logo_mfund@2x.png 2x"
					alt="Logo BMVI"
				/>
			</div>
		</div>
		<div class="mb-8">
			<div class="mb-2 font-bold">Datenquellen</div>
			<div>OpenStreetMap Mitwirkende</div>
			<div>Bundesamt für Kartografie und Geodäsie (BKG)</div>
			<div>European Environment Agency (EEA)</div>
			<div>European Space Agency (ESA)</div>
			<div>Statistisches Bundesamt</div>
			<div>Land NRW - Bezirksregierung Köln</div>
			<div>IT NRW</div>
		</div>
		<a sveltekit:prefetch href="/impressum" class="font-bold hover:text-select">Impressum</a>
	</div>
</footer>

<style>
</style>
