<script>
	import { page } from '$app/stores';
	import { isMobile, queryString } from '$lib/stores/storesShared';
	import { slide } from 'svelte/transition';

	import { onMount } from 'svelte';
	import { base } from '$app/paths';

	let mobileNavVisibile = false;

	function onClick() {
		mobileNavVisibile = false;
	}

</script>

{#if $isMobile}
	<!-- Mobile nav -->
	<header class="flex justify-end items-center h-14 px-5">
		<div class="space-y-2" on:click={() => (mobileNavVisibile = !mobileNavVisibile)}>
			<div
				class="bg-text h-1 w-8 rounded transition-transform {mobileNavVisibile
					? 'rotate-45 translate-y-1.5'
					: ''}"
			/>
			<div class="bg-text h-1 w-8 rounded" class:hidden={mobileNavVisibile} />
			<div
				class="bg-text h-1 w-8 rounded transition-transform {mobileNavVisibile
					? '-rotate-45 -translate-y-1.5'
					: ''}"
			/>
		</div>
	</header>

	{#if mobileNavVisibile}
		<nav
			transition:slide
			class="flex justify-center items-center p-10 bg-white absolute top-14 z-50 w-full"
		>
			<ul class="space-y-5 text-center">
				<li class:active={$page.path === '/'} on:click={onClick}>
					<a sveltekit:prefetch href="/{$queryString}">Incora Dashboard</a>
				</li>
				<li class:active={$page.path.includes('/daten')} on:click={onClick}>
					<a sveltekit:prefetch href="/daten">Daten</a>
				</li>
				<li class:active={$page.path.includes('/indikatoren')} on:click={onClick}>
					<a sveltekit:prefetch href="/indikatoren">Indikatoren</a>
				</li>
				<li class:active={$page.path === '/about'} on:click={onClick}>
					<a sveltekit:prefetch href="/about">Über das Portal</a>
				</li>
				<li class:active={$page.path === '/faq'} on:click={onClick}>
					<a sveltekit:prefetch href="/faq">FAQ</a>
				</li>
			</ul>
		</nav>
	{/if}
{:else}
	<!-- Desktop nav -->
	<header class="flex justify-between items-center h-14 px-10 border-b">
		<nav class="flex">
			<ul class="flex flex-row space-x-20 items-center">
				<li class:active={$page.path === '/'} class="font-bold">
					<a sveltekit:prefetch href="/{$queryString}">Incora Dashboard</a>
				</li>
				<li class:active={$page.path.includes('/daten')}>
					<a sveltekit:prefetch href="/daten">Daten</a>
				</li>
				<li class:active={$page.path.includes('/indikatoren')}>
					<a sveltekit:prefetch href="/indikatoren">Indikatoren</a>
				</li>
				<li class:active={$page.path === '/about'}>
					<a sveltekit:prefetch href="/about">Über das Portal</a>
				</li>
				<li class:active={$page.path === '/faq'}>
					<a sveltekit:prefetch href="/faq">FAQ</a>
				</li>
			</ul>
		</nav>
		<ul class="flex flex-row space-x-10">
			<a href="https://www.ils-forschung.de/" target="_blank" rel="noopener noreferrer"
				><img
					src="{base}/images/logo-ils.png"
					srcset="{base}/images/logo-ils.png 1x, {base}/images/logo-ils@2x.png 2x"
					alt="Logo ILS"
				/></a
			>
			<a
				href="http://www.bbsr.bund.de/BBSR/DE/startseite/_node.html"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					src="{base}/images/logo-bbsr.jpg"
					srcset="{base}/images/logo-bbsr.jpg 1x, {base}/images/logo-bbsr@2x.jpg 2x"
					alt="Logo BBSR"
					style="margin-top: 4px;"
				/></a
			>
			<a href="http://www.mundialis.de/de/" target="_blank" rel="noopener noreferrer"
				><img
					src="{base}/images/logo-mundialis.jpg"
					srcset="{base}/images/logo-mundialis.jpg 1x, {base}/images/logo-mundialis@2x.jpg 2x"
					alt="Logo Mundialis"
				/></a
			>
		</ul>
	</header>
{/if}

<style lang="scss">
	@import '../../styles/variables.scss';
	li.active {
		color: $color-select;
		font-weight: 700;
	}
</style>
