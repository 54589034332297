<script>
	import { disclaimerModalVisible } from '$lib/stores/storesShared';
	import { isMobile } from '$lib/stores/storesShared';
	import DisclaimerModalText from './DisclaimerModalText.svelte';

	let modalWidth;
	let containerWidth;

	$: translateX = `transform: translateX(${modalWidth / 2 - containerWidth / 2}px);`;
	$: setContainerWidth = $isMobile ? 'width: 90%;' : 'width: 70%;';
	$: setContainerHeight = $isMobile ? 'height: 90vh;' : 'height: 67vh;';
	$: setContainerPadding = $isMobile ? 'pt-8' : 'pt-24';
</script>

<div
	bind:clientWidth={modalWidth}
	id="modal-disclaimer"
	class="fixed top-0 left-0 h-full w-full flex {setContainerPadding}"
	on:click={() => {
		$disclaimerModalVisible = !$disclaimerModalVisible;
	}}
>
	<div>
		<div
			bind:clientWidth={containerWidth}
			class="container bg-white rounded text-sm p-10 overflow-auto"
			style="{setContainerWidth} {setContainerHeight} {translateX}"
		>
			<div class="wrapper">
				<div
					class="icon-close cursor-pointer text-right text-lg lg:text-base"
					on:click={() => {
						$disclaimerModalVisible = !$disclaimerModalVisible;
					}}
				/>
				<header class="flex justify-between pt-6">
					<ul class="flex flex-row justify-start space-x-10 mb-12">
						<a href="https://www.ils-forschung.de/" target="_blank" rel="noopener noreferrer"
							><img
								src="/images/logo-ils.jpg"
								srcset="/images/logo-ils.jpg 1x, /images/logo-ils@2x.jpg 2x"
								alt="Logo ILS"
							/></a
						>
						<a
							href="http://www.bbsr.bund.de/BBSR/DE/startseite/_node.html"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="/images/logo-bbsr.jpg"
								srcset="/images/logo-bbsr.jpg 1x, /images/logo-bbsr@2x.jpg 2x"
								alt="Logo BBSR"
							/></a
						>
						<a href="http://www.mundialis.de/de/" target="_blank" rel="noopener noreferrer"
							><img
								src="/images/logo-mundialis.jpg"
								srcset="/images/logo-mundialis.jpg 1x, /images/logo-mundialis@2x.jpg 2x"
								alt="Logo Mundialis"
							/></a
						>
					</ul>
				</header>
				<DisclaimerModalText />
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	#modal-disclaimer {
		z-index: 100;
		background-color: rgba(0, 0, 0, 0.5);
	}
</style>
